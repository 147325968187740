<template>
  <div class="row">
    <div class="col-12">

      <div class="accordion" id="filterAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters"
                    aria-expanded="false" aria-controls="filters">
              {{ $t('events.filters.title') }} ({{ $t('events.filters.click_to_open') }})
            </button>
          </h2>
          <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne"
               data-bs-parent="#filterAccordion">
            <div class="accordion-body">
              <Form @submit="(values) => loadEvents(pagination.page, values)" :validation-schema="schema">
                <div class="row">
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label 
                      for="vehicleRegistrationFilter"
                      class="form-label">{{ $t('events.filters.vehicle_registration') }}
                    </label>
                    <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter"/>
                    <ErrorMessage name="registration" class="error-feedback"/>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="siteFilter" class="form-label">{{ $t('events.filters.site') }}</label>
                    <Field id="siteFilter" class="form-select" v-model='search.site_id' @change="siteUpdated"
                           name="site" as="select">
                      <option value="" selected> {{ $t('events.filters.select_a_site') }} </option>
                      <option v-for="site in sites" :key="'site-' + site.id" :value='site.id'>{{ site.name }}</option>
                    </Field>
                    <ErrorMessage name="site" class="error-feedback"/>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="siteFilter" class="form-label">{{ $t('events.filters.camera') }}</label>
                    <Field id="siteFilter" class="form-select" v-model='search.camera_id' name="camera" as="select">
                      <option value="" selected> {{ $t('events.filters.select_a_camera') }} </option>
                      <option v-for="camera in cameras" :key="'camera-' + camera.id" :value='camera.id'>
                        {{ camera.name }}
                      </option>
                    </Field>
                    <ErrorMessage name="camera" class="error-feedback"/>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="directionFilter" class="form-label">{{ $t('events.filters.direction') }}</label>
                    <Field id="directionFilter" class="form-select" name="direction" as="select">
                      <option value="" selected>- {{ $t('events.filters.select_a_direction') }} -</option>
                      <option value='reverse'>{{ $t('events.filters.leaving_site') }}</option>
                      <option value='forward'>{{ $t('events.filters.entering_site') }}</option>
                    </Field>
                    <ErrorMessage name="direction" class="error-feedback"/>
                  </div>
                </div>
                <div class="row m-t-xs">
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="startDateFilter" class="form-label">{{ $t('events.filters.start_date') }}</label>
                    <Field type="date" name="start_date" class="form-control" id="startDateFilter"/>
                    <ErrorMessage name="start_date" class="error-feedback"/>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="startTimeFilter" class="form-label">{{ $t('events.filters.start_time') }}</label>
                    <Field type="time" name="start_time" class="form-control" id="startTimeFilter"/>
                    <ErrorMessage name="start_time" class="error-feedback"/>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="endDateFilter" class="form-label">{{ $t('events.filters.end_date') }}</label>
                    <Field type="date" name="end_date" class="form-control" id="endDateFilter"/>
                    <ErrorMessage name="end_date" class="error-feedback"/>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <label for="endTimeFilter" class="form-label">{{ $t('events.filters.end_time') }}</label>
                    <Field type="time" name="end_time" class="form-control" id="endTimeFilter"/>
                    <ErrorMessage name="end_time" class="error-feedback"/>
                  </div>
                </div>
                <div class="row m-t-xs">
                  <div class="col-12">
                    <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                      <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                      <span>{{ $t('events.filters.search') }}</span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row m-t-xs">
    <div class="col-12">

      <div class="card widget widget-list">

        <div class="card-header">
          <h5 class="card-title">{{ $t('events.events') }}</h5>
        </div>
        <div class="card-body" style="overflow-x: hidden">
          <div v-show="loading" style="width: 100%; text-align: center;">
            <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
              <span class="visually-hidden">{{ $t('events.loading') }}...</span>
            </div>
          </div>

          <pagination v-show="!loading" :config="pagination" @search="(page) => loadEvents(page, currentParams)"/>

          <table v-show="!loading" class="table table-borderless">
            <tbody>
            <tr v-for="event in events" :key="'events-' + event.id" style="vertical-align: middle;">
              <td>
                <!-- <event-image @click="openEventImage(event)" v-if="event.image !== null"
                             class="widget-list-item-icon-image" style="width: 150px; height: auto; cursor: pointer;"
                             :filename="event.image" :site_id="event.site.id" :id="'eventimage-' + event.id"/> -->
                <EventImage 
                  @click="openEventImage(event)"
                  v-if="event.image !== null"
                  class="widget-list-item-icon-image"
                  style="width: 150px; height: auto; cursor: pointer;"
                  :filename="event.image"
                  :site_id="event.site.id"
                  :id="'eventimage-' + event.id"                
                />
              </td>
              <td style="width: 100%;">
                <span class="badge"
                      :class="{'badge-success': event.status === 'active', 'badge-danger': event.status === 'blacklisted'}">{{ event.status.toUpperCase() }}</span><br/>
                <span class="bold">{{ event.site.name }}</span>: {{ event.camera.name }}
              </td>
              <td style="white-space: nowrap;">
                {{ event.direction === 'reverse' ? this.$t('events.filters.leaving_site') : this.$t('events.filters.entering_site') }}
              </td>
              <td style="white-space: nowrap;">
                <!-- <timestamp :time="event.created_at" format="dd/MM/yyyy HH:mm:ss"></timestamp> -->
                <Timestamp :time="event.created_at" format="dd/MM/yyyy HH:mm:ss"/>
              </td>
              <td style="white-space: nowrap;">
                <span class="vrm bold number-plate-gbr">{{ event.vehicle.plate }}</span>
              </td>
              <td style="white-space: nowrap;">
                <button v-if="event.status === 'active'" class="btn btn-danger btn-style-light flex-grow-1"><i
                    class="material-icons">close</i>{{ $t('events.blacklist') }}
                </button>
                <button v-else class="btn btn-primary btn-style-light flex-grow-1"><i
                    class="material-icons">done</i>{{ $t('events.unblacklist') }}
                </button>
              </td>
              <td style="white-space: nowrap;">
                <button class="btn btn-primary btn-style-light flex-grow-1"
                        @click="$router.push(`/events/${event.id}`)"><i
                    class="material-icons">visibility</i>{{ $t('events.view') }}
                </button>
              </td>
            </tr>
            </tbody>
          </table>

          <pagination v-show="!loading" :config="pagination" @search="(page) => loadEvents(page, currentParams)"/>
        </div>
      </div>

    </div>
  </div>

  <teleport to="body">
    <div class="modal fade" id="eventImageModal" tabindex="-1" style="display: none; padding-right: 17px;"
         aria-modal="true" role="dialog">
      <div class="modal-dialog modal-dialog-centered" style="height: 80vh; width: 75%; max-width: 75%;">
        <event-image v-if="currentEvent !== null" style="width: 100%; height: auto;" :thumb="false"
                     :filename="currentEvent.image" :site_id="currentEvent.site.id"
                     :id="'eventimage-' + currentEvent.id" :storage="currentEvent.storage_account"/>
        <p class="closePopup">
          {{ $t('events.press') }} <code>ESC</code> {{ $t('events.event_close') }}
        </p>
      </div>
    </div>
  </teleport>
</template>

<script>
import CameraService from '../../services/camera.service';
import ClientService from '../../services/client.service';
import EventService from '../../services/event.service';
import EventImage from '../../components/EventImage.vue';
import Timestamp from '../../components/Timestamp.vue';
import {Form, Field, ErrorMessage} from 'vee-validate';
import * as yup from 'yup';
import Pagination from '../../components/Pagination.vue';

export default {
  components: {EventImage, Timestamp, Form, Field, ErrorMessage, Pagination},
  name: 'Events',
  data() {

    // query('client_id').isNumeric(),
    // query('site').default(-1).isNumeric(),
    // query('registration').default('').isString(),
    // query('camera').default(-1).isNumeric(),
    // query('direction').default('').isString(),
    // query('status').default('').isString(),
    // query('start_date').default('').isString(),
    // query('end_date').default('').isString(),
    // query('start_time').default('').isString(),
    // query('end_time').default('').isString(),
    const schema = yup.object().shape({
      registration: yup.string().min(3, 'Must be at least 3 characters!').max(12, 'Must be no more than 12 characters!'),
      start_date: yup.date(),
      end_date: yup.date(),
      start_time: yup.string().matches('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$', 'Must be a valid HH:mm time'),
      end_time: yup.string().matches('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$', 'Must be a valid HH:mm time'),
      direction: yup.string()
    });
    return {
      loading: true,
      events: [],
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0,
        maxPage: 0
      },
      schema: schema,
      sites: [],
      cameras: [],
      search: {
        site_id: -1,
        camera_id: -1
      },
      currentParams: {},
      currentEvent: null
    }
  },
  mounted() {
    this.loadEvents();
    this.loadSites();
    this.loadCameras();
  },
  methods: {
    loadEvents(page = 1, params = {}) {
      this.loading = true;
      this.currentParams = params;
      EventService.getEvents(page, this.$store.state.auth.user.selectedClient, params).then((response) => {
        this.pagination = response.data.pagination;
        this.events = response.data.events;
        this.loading = false;
      })
    },
    loadSites() {
      ClientService.getClientSites(this.$store.state.auth.user.selectedClient, true).then(response => {
        this.sites = response.data.sites.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      });
    },
    loadCameras(site = null) {
      CameraService.getCameras(site).then(response => {
        this.cameras = response.data.cameras;
      });
    },
    siteUpdated() {
      this.loadCameras(this.search.site_id);
    },
    openEventImage(event) {
      this.currentEvent = event;
      $('#eventImageModal').modal('toggle');
    }
  }
}
</script>

<style scoped>
.vrm {
  padding: 5px 7.5px;
  background-color: yellow;
  border-radius: 5px;
  color: black !important;
}

.widget-list-item-icon {
  background-color: transparent !important;
}

.number-plate-gbr {
  background-image: url(/images/uk-plate-logo.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding-left: 2rem !important;
}
</style>